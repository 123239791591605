.transparent-bg {
  background-color: transparent !important;
  color: white !important;
  border-bottom: 1.5px solid #333333 !important;
}

.faq-title {
  font-size: 24px !important;
  font-weight: 400 !important;
  padding: 24px 0 !important;
}

.faq-question {
  margin-right: 8px !important;
}

@media (max-width: 1248px) {
  .faq-title {
    font-size: 20px !important;
    padding: 20px 0 !important;
  }
}

@media (max-width: 770px) {
  .faq-title {
    font-size: 1rem !important;
  }
}

.faq-bottom {
  padding: 30px 35px !important;
}

.faq-content {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #bbbbbb;
}

@media (max-width: 770px) {
  .faq-content {
    font-size: 1rem !important;
  }
}

.MuiPaper-root {
  margin: 0 !important;
  color: white;
  background-color: transparent;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.MuiCollapse-wrapper {
  background-color: #2222226e !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

.MuiTypography-root {
  font-family: SUIT !important;
}

.MuiAccordionSummary-content {
  padding-right: 12px !important;
}

.expandIconWrapper,
.collapsIconWrapper {
  font-size: 2rem;
  font-weight: 200;
  color: #bbbbbb;
}