/* swiper custom */
.swiper {
  padding-top: 10px !important;
}
.swiper-pagination {
  top: -10px !important;
  z-index: 0 !important;
  height: fit-content;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  background-color: rgba(204, 204, 204, 1) !important;
  border-radius: 0px !important;
  width: 13px !important;
  height: 2px !important;
}

.swiper-pagination-bullet-active {
  background-color: rgba(102, 102, 102, 1) !important;
  border-radius: 0px !important;
  width: 30px !important;
  height: 2px !important;
}

.mobile-artiside-button-wrap {
  display: flex;
  gap: 16px;
  margin-top: 60px;
  justify-content: center;
}
.mobile-only {
  display: none;
}

@media (max-width: 770px) {
  .mobile-artiside-button-wrap {
    gap: 30px;
    margin-top: 10px;
    justify-content: center;
  }
  .artiside-button {
    width: fit-content !important;
    font-size: 12px !important;
    letter-spacing: normal !important;
    padding: 0 35px !important;
    height: 40px !important;
    margin-top: 30px !important;
  }
  .artiside-button svg {
    width: 6px;
  }
  .mobile-only {
    display: block;
  }
}
@media (max-width: 400px) {
  .mobile-textarea {
    font-size: 14px !important;
    line-height: 16px !important;
    p {
      margin-top: 5px !important;
    }
  }
  .artiside-button {
    width: fit-content !important;
    font-size: 12px !important;
    letter-spacing: normal !important;
    padding: 0 35px !important;
    height: 40px !important;
    margin-top: 0px !important;
  }
}
