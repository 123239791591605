.card-wrapper {
  display: flex;
  max-width: 1416px;
  margin: 0 auto;
  gap: 24px;
}

.card-wrapper-mob {
  display: none;
}

.card-wrapper-web {
  display: flex;
}

@media (max-width: 1248px) {
  .card-wrapper-web {
    display: none;
  }
  .card-wrapper-mob {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

@media (max-width: 770px) {
  .card-wrapper-mob {
    grid-template-columns: 100%;
    width: 100%;
  }
}

.card {
  width: 100%;
  color: white;
  cursor: pointer;
  z-index: 5;
}

@media (min-width: 1249px) {
  .card {
    max-width: 310px
  }
}

.card-top {
  overflow: hidden;
}

.card-top img {
  width: 100%;
  height: 183px;
  object-fit: cover;
  transition: 0.2s;

}

.card:hover .card-top img {
  transform: scale(1.05);
  overflow: hidden;
}

.card-bottom {
  padding: 1rem 10px;
}

@media (max-width: 770px) {
  .card-bottom {
    padding: 10px;
  }
}
.card-title {
  font-size: 18px;
  margin-bottom: 1rem;
  height: fit-content;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-text {
  color: #aaaaaa;
  height: 40px;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-footer {
  padding: 1rem 10px;
  font-size: 14px;
  color: #666666;
}

@media (max-width: 770px) {
  .card-footer {
    padding: 1.2rem 10px;
  }
}