.partners-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 350px;
  margin-bottom: 100px;
  padding-top: 30px;
  opacity: 0;
  transition: 1.1s;
  transform: translateY(30px);
}

@media (max-width: 770px) {
  .partners-section {
    margin-top: 200px;
    margin-bottom: 0px;
  }
}

.partners-contents {
  margin: 80px auto 0;
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  max-width: 1250px;
  padding: 0 40px;
  @media (max-width: 1249px) {
    max-width: 1050px;
  }
  @media (max-width: 1049px) {
    max-width: 850px;
  }
  @media (max-width: 849px) {
    max-width: 650px;
  }
  @media (max-width: 790px) {
    max-width: 360px;
  }
}

@media (max-width: 790px) {
  .partners-contents {
    gap: 40px 80px;
  }
}

@media (max-width: 540px) {
  .partners-contents {
    max-width: 360px;
  }
}

.partners-contents > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 100px;
}

@media (max-width: 790px) {
  .partners-contents > div {
    width: 100px;
    height: 60px;
  }
  .partners-contents > div > img {
    width: 100%;
  }
  .mobile-sm {
    width: auto !important;
    height: 100%;
  }
}

.partners-section.translate-up {
  transform: translateY(0px);
  transition: 0.5s;
  transition-duration: 0.8s;
  opacity: 1;
}
