.footer {
  position: relative;
  color: white;
  padding: 0 40px;
  background-color: #040405;
}

@media (max-width: 770px) {
  .footer {
    padding: 0 30px;
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1416px;
  margin: 0 auto;
}

@media (max-width: 770px) {
  .footer-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "right"
      "left";
  }

  .footer-right {
    grid-area: right;
  }

  .footer-left {
    grid-area: left;
  }
}

.footer-left {
  display: flex;
  align-items: center;
  color: #888888;
  font-size: 1rem;
}

@media (max-width: 1280px) {
  .footer-left {
    font-size: 12px;
  }
}

.footer-left p {
  margin-left: 45px;
  pointer-events: none;
}

.footer-right p {
  font-size: 22px;
  font-weight: 500;
  pointer-events: none;
}

.footer-right {
  display: flex;
  align-items: center;
}

@media (min-width: 770px) and (max-width: 1024px) {
  .footer-right {
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }
}

.footer-right .mail {
  margin-left: 35px;
  font-size: 24px;
  color: #555555;
  border-bottom: 1px solid #555555;
}

.footer-right .mail:hover {
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .footer-right p {
    font-size: 1rem;
  }

  .footer-right p:nth-child(1) {
    min-width: 78px;
  }

  .footer-right .mail {
    font-size: 14px;
    margin-left: 21px;
  }

  .footer-left p {
    margin-left: 21px;
  }
}

@media (max-width: 770px) {
  .footer-left,
  .footer-right {
    flex-direction: column;
    justify-content: center;
    padding: 35px 0;
  }

  .footer-right {
    border-bottom: 1px solid #232323;
  }

  .footer-left svg {
    width: 80px;
    margin-bottom: 20px;
  }

  .footer-right p:nth-child(1) {
    margin-bottom: 20px;
  }

  .footer-right .mail {
    margin-left: 0;
    font-size: 18px;
  }

  .footer-left p {
    margin-left: 0px;
  }
}

@media (min-width: 770px) {
  .footer-container {
    height: 172px;
  }
}

.footer-contact {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 770px) {
  .footer-contact {
    flex-direction: column;
  }

  .footer-right {
    gap: 30px;
  }
}

.chainlink {
  width: 204px;
  margin-left: 30px;
}

@media (max-width: 770px) {
  .chainlink {
    margin-left: 0;
  }
}