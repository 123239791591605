.experience-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.page3-wrapper__experience {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 1440px;
  position: relative;
}

@media (max-width: 1400px) {
  .page3-section__experience {
    display: grid;
    grid-template-columns: 1fr;
  }

  .page3-wrapper__experience {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

.page3-imgwrapper__experience {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
}

.exp-text {
  position: absolute;
  left: 50%;
  color: white;
  font-size: 48px;
  margin-left: 135px;
  margin-top: -40px;
}

@media (min-width: 1401px) {
  .exp-text {
    min-width: 650px;
  }
}

@media (max-width: 1400px) {
  .exp-text {
    font-size: 40px;
    margin-left: 0;
    top: 68%;
    left: initial;
    position: initial;
    margin-top: 36%;
    z-index: 1;
  }
}

.text-top__experience {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 140px;
  margin-left: -205px;
}

@media (max-width:1400px) {
  .text-top__experience {
    margin-left: 0px;
    margin-top:135px;
  }

  .text-bottom__experience {
    justify-content: end;
  }
}

.text-bottom__experience {
  margin-left: -35px;
  font-size: 48px;
  margin-top: -10px;
}

@media (min-width: 1401px) {
  .text-bottom__experience {
    min-width: 533px;
  }
}

@media (max-width: 1400px) {
  .text-bottom__experience {
    margin-left: 0px;
    margin-top: -6px;
  }
}

.before-line {
  display: none;
  margin-right: 40px;
}

.before-line2 {
  display: none;
  margin-right: 20px;
  margin-top: 3px;
}

@media (min-width: 1401px) {
  .before-line {
    display: block;
  }
}

@media (max-width: 1400px) {
  .before-line2 {
    display: block;
  }
}

.sub-text__experience {
  margin-top: 100px;
  display: none;
}

@media (min-width: 1399px) {
  .sub-text__experience {
    display: block;
    margin-top: 20%;
    margin-left: -18px;
  }
}

@media (max-width: 1400px) {
  .sub-text__experience {
    margin-top: 70px;
    margin-left: 75px;
  }
}

.sub-text__experience p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 1px;
  color: #aaaaaa;
  max-width: 344px;
}

.sub-text__experience p::first-letter {
  font-size: 24px;
  font-weight: 600;
}

.page3-frameimg__experience {
  height: 60%;
  width: 100%;
  margin-bottom: 50px;
  filter: brightness(1.2);
}

@media (max-width:770px) {
  .page3-frameimg__experience {
    height: 65vh;
  }
}

@media (min-width:1401px) {
  .page3-frameimg__experience {
    height: 60%;
    width: 90%;
    filter: brightness(1.2);
  }
}

@media (max-width: 550px) {
  .exp-text {
    font-size: 24px;
    min-width: none;
    margin-top: 30%;
    padding: 0 30px;
  }

  .mobile-text__experience br {
    display: none;
  }
}

/* Animations */
.opacity-effect {
  transition-delay: 0.5s;
  transition: 0.8s;
  opacity: 1;
}

@media (min-width: 1401px) {
  .title-effect__experience {
    opacity: 1;
    transition: opacity 0.5s transform 1s;
    transition-delay: 0.2s;
    transform: translateX(-8%);
    transition-duration: 1.2s;
  }
}

.sub-text__experience {
  opacity: 0;
  transition: 0.5s;
}

@media (min-width: 1401px) {
  .sub-effect {
    opacity: 1;
    transform: translateY(-25%);
    transition: opacity 0.5s transform 1s;
    transition: 1s;
    transition-delay: 0.3s;
    transition-duration: 1s;
  }
}