.first-img {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}

.first-img > img:nth-child(2) {
  position: absolute;
  top: 255px;
  max-width: 470px;
  max-height: 600px;
  width: 100%;
  height: 100%;
}

.first-img::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  z-index: 2;
  background: url("@/img/1.main/v2/change/bottom.png")
}

.first-img::-webkit-scrollbar {
  display: none;
}

.logo-wrapper {
  z-index: 5;
  position: absolute;
  top: 860px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.frame {
  background: url("@/img/LSS/pillar-v3.png");
  background-size: cover;
  background-position: center;
  /* max-width: 1381px; */
  width: 100%;
  height: 100vh;
  z-index: 2;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1400px) {
  .frame {
    background: url("@/img/1.main/v2/change/mobile-background.png");
    background-size: cover;
    background-position: center;
    /* max-width: 1381px; */
    width: 100%;
    height: 100vh;
    z-index: 2;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.frame-wrapper {
  max-width: 390px;
  position: relative;
  color: #1f3843;
  text-align: center;
  font-size: 28px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-icon {
  position: absolute;
  top: 228px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contents-section {
  width: 100%;
  height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.contents-section::-webkit-scrollbar {
  display: none;
}

.frame-wrapper p {
  font-size: 28px;
}

@media (max-width: 800px) {
  .contents-section {
    height: 100vh;
    overflow: hidden;
  }

  .first-img {
    height: 950px;
    background-position: center;
  }

  .first-img::before {
    display: none;
  }

  .first-img::after {
    display: none;
  }

  .frame {
    /* background-size: 848px; */
    background-position: center center;
    background-repeat: no-repeat;
  }

  .play-icon {
    top: 186px;
  }

  .play-icon svg {
    width: 86px;
  }

  .logo-wrapper {
    top: 600px;
    bottom: auto;
  }

  .frame-wrapper {
    top: 310px;
  }

  .frame-wrapper p {
    font-size: 20px;
    letter-spacing: -0.02em;
  }

  .frame-wrapper::after {
    top: 120px;
  }
}

@media (min-width: 1280px) and (max-height: 1080px) {
  .logo-wrapper {
    top: 820px;
  }
}

/* .frame-wrapper2 {
  width: 588px;
  height: 762px;
} */

.framing {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 112px;
}

.framing > img {
  position: absolute;
  /* margin-top: 180px; */
  width: 550px;
  height: 760px;
  z-index: 1;
  top: 100px
}

.frame-containers {
  position: absolute;
  width: 550px;
  height: 760px;
}

.frame-containers > img {
  z-index: 1;
  width: 550px;
  height: 760px;
}

.frame-containers > video {
  position: absolute;
  width: 360px;
  top: 320px;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 769px) and (max-width: 1400px) {
  .frame-containers {
    margin-top: -160px;
  }

  .frame-containers > img {
    width: 440px;
    height: 580px;
  }

  .frame-containers > video {
    width: 280px;
    top: 245px;
  }
}

@media (max-width: 768px) {
  .framing {
    height: 667px;
  }
  
  .frame-containers > img {
    position:absolute;
    width: 290px;
    height: 404px;
    left: 50%;
    top: -80px;
    transform: translate(-50%, -50%);
  }

  .frame-containers > video {
    width: 190px;
    top: -110px;
  }
}

.title-textarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  z-index: 5;
  position: absolute;
}

@media (max-width: 1400px) {
  .title-textarea {
    top:-30px;
    align-items: center;
  }
}

@media (min-width: 1401px) {
  .title-textarea {
    left: 70px;
  }
}

.contents-textarea {
  /* width: 10%; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 5;
  position: absolute;
  margin-top: 320px;
}

@media (min-width: 1401px) {
  .contents-textarea {
    right: 110px;
    margin-top: -60px;
  }
}

.swiper-pagination {
  justify-content: flex-start;
}

.swiper-slide {
  justify-content: flex-start;
}

.frame-containers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  width: fit-content;
  height: fit-content;
}

@media (min-width: 769px) and (max-width: 1400px) {
  .title-textarea {
    top:120px;
  }

  .contents-textarea {
    margin-top: 500px;
  }
}

.section-lss {
  background-image: url('@/img/1.main/v2/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
}