.nftfi-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.page3-wrapper__nftfi {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 1440px;
  position: relative;
}

@media (max-width: 1400px) {
  .page3-section__nftfi {
    display: grid;
    grid-template-columns: 1fr;
  }

  .page3-wrapper__nftfi {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95vh;
  }
}

.page3-imgwrapper__nftfi {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  z-index: -1;
}

.page3-imgwrapper__nftfi::after {
  content: "";
  position:absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9346332282913166) 25%, rgba(0,0,0,0) 40%);

}

@media (min-width: 1400px) {
  .page3-imgwrapper__nftfi {
    justify-content: flex-start;
  }

  .page3-imgwrapper__nftfi::after {
    display: none;
  }
}

.nft-text {
  left: 275px;
  color: white;
  font-size: 48px;
  margin-left: 220px;
}

@media (min-width: 1401px) {
  .nft-text {
    min-width: 650px;
    margin-top: 40px;
  }
}

@media (max-width: 1400px) {
  .nft-text {
    font-size: 40px;
    margin-left: 0;
    top: 68%;
    left: initial;
    position: initial;
    margin-top: 35%;
    z-index: 1;
  }
}

.text-top__nftfi {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: -76px;
}

@media (max-width: 1400px) {
  .text-top__nftfi {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.text-bottom__nftfi {
  margin-left: 0px;
  font-size: 48px;
  margin-top: -4px;
  position: relative;
}

@media (max-width: 1400px) {
  .text-bottom__nftfi {
    /* margin-left: 40px; */
    font-size: 22px;
  }

  .text-bottom__nftfi > p > span > span {
    /* margin-left: -30px; */
    top: 17px;
  }
}
/* 
@media (min-width: 767px) and (max-width: 1400px) {
  .text-bottom__nftfi > p > span > span {
    margin-left: 58px;
    top: 54px;
  }
} */

.before-line {
  display: none;
  margin-right: 40px;
}

.before-line2 {
  display: none;
  margin-right: 40px;
}

@media (min-width: 1401px) {
  .before-line {
    display: block;
  }
}

@media (max-width: 1400px) {
  .before-line2 {
    display: block;
  }
}

.sub-text__nftfi {
  margin-top: 20%;
  display: none;
  opacity: 0;
}

@media (min-width: 1399px) {
  .sub-text__nftfi {
    display: block;
  }
}

@media (max-width: 1400px) {
  .sub-text__nftfi {
    margin-top: 70px;
  }
}

.sub-text__nftfi p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1px;
  color: #bbbbbb;
  min-width: 350px;
  margin-left: 85px;
}

.sub-text__nftfi p::first-letter {
  font-size: 24px;
  font-weight: 600;
}

.img-piece {
  position: absolute;
  max-width: 186px;
  max-height: 230px;
  height: 30%;
  top: 202px;
  right: 452px;
  opacity: 0;
}

@media(min-width:1056px) and (max-width: 1400px) {
  .img-piece {
    right: 170px;
    top: 204px;
  }
}

@media (max-width: 1056px) {
  .page3-frameimg__nftfi {
    width: 349px;
    height: 634px;
  }

  .img-piece {
    display: none;
  }
}

@media (min-width:1400px) {
  .img-piece {
    top:208px;
    right:212px;
  }
}

.img-scale-effect {
  opacity: 1;
  transition: 1s;
  transition-delay: 1s;
  transition-duration: 2s;
  transform: translateY(-24px)
}

@media (max-width: 1400px) {
  .page3-imgwrapper__nftfi img {
    height: 750px;
    width: 100%;
    object-fit: cover;
  }
}

@media (max-width: 770px) {
  .page3-imgwrapper__nftfi img {
    height: 375px;
  }
}

@media (max-width: 770px) {
  .nft-text {
    font-size: 24px;
    min-width: none;
    margin-top: 54%;
    padding: 0 30px;
  }

  .mobile-text__nftfi br {
    display: none;
  }
}

@media (max-width: 1280px) {
  .mobile-text__nftfi {
    max-width: none;
  }
}

.nft-effect {
  transform: translateX(10px);
  transition: transform 5s;
}

@media (max-width: 1400px) {
  .nft-effect {
    transform: none;
  }
}

/* Animations */
.opacity-effect {
  transition-delay: 3s;
  transition: 0.8s;
  opacity: 1;
}

.page3-imgwrapper__nftfi {
  /* opacity: 0; */
  transition: 1s;
  transition-delay: 0.5s;
  transition-duration: 1.3s;
}

@media (min-width: 1400px) {
  .page3-frameimg__nftfi {
    width: 90%;
    margin-bottom: 170px; 
  }
}

/* .img-effect {
  opacity: 1;
} */

@media (min-width: 1400px) {
  .title-effect__nftfi {
    opacity: 1;
    transition: opacity 0.5s transform 1s;
    transition-delay: 0.2s;
    transform: translateX(10%);
    transition-duration: 1.2s;
  }
}

.sub-text__nftfi {
  opacity: 0;
  transition: 0.5s;
}

@media(min-width: 1401px) and (max-width:1728px) {
  .title-effect__nftfi {
    transform: translateX(10%);
  }
} 

@media (min-width: 1401px) {
  .sub-effect__nftfi {
    opacity: 1;
    transition: 1s;
    transition-delay: 0.3s;
    transition-duration: 1s;
    transform: translateY(-25%);
  }
}

.align-text__bottom {
  position: absolute;
  /* left: 0; */
  line-height: 80px;
}

.nft-video {
  width: 85%;
  margin-bottom: 20%;
}