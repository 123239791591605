.qna-section {
  margin-top: 350px;
  padding-top: 30px;
  opacity: 0;
  transition: 1.1s;
  transform: translateY(30px);
}

@media (max-width: 770px) {
  .qna-section {
    margin-top: 200px;
  }
}

.faq-contents {
  margin: 80px auto 0;
  max-width: 1416px;
  padding: 0 40px;
}

@media (max-width: 770px) {
  .faq-contents {
    padding: 0 30px;
    margin: 40px auto 0;
  }
}

.qna-section.translate-up {
  transform: translateY(0px);
  transition: 0.5s;
  transition-duration: 0.8s;
  opacity: 1;
}