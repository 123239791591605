/* page3-section */
.page3-section {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* text-title */
.text-title {
  font-size: 18px;
  color: #aaaaaa;
  margin-bottom: 30px;
  font-weight: 600;
}

@media (max-width: 770px) {
  .text-title {
    font-size: 1rem;
    margin-bottom: 20px;
  }
}

/* text-accent */
.text-accent {
  font-size: 90px;
}

@media (max-width: 1400px) {
  .text-accent {
    font-size: 88px;
  }
}

@media (max-width: 550px) {
  .text-accent {
    font-size: 50px;
  }
}

/* text-bottom */
.text-bottom {
  display: flex;
  align-items: center;
}

/* mobile-text */
.mobile-text {
  display: none;
  margin-top: 70px;
  opacity: 0;
  transition: 0.5s;
}

.mobile-text p {
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0px;
  text-align: center;
  font-weight: 400;
  color: #aaaaaa;
}

.mobile-text p::first-letter {
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 1400px) {
  .mobile-text {
    display: inline-block;
  }
}

/* contents.css -> mobile-size-text */
.mobile-size-text {
  display: none;
  opacity: 0;
  transition: 0.8s;
}

@media (max-width: 1400px) {
  .mobile-size-text {
    display: block;
  }
}

/* web-title */
.web-title {
  opacity: 0;
  transition: 0.5s;
}

/* title-section */
.title-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* title-effect */
.title-effect {
  opacity: 1;
  transition: opacity 1s, transform 1s;
  transition-duration: 2s;
  transition-delay: 0.8s;
}

@media (max-width: 1400px) {
  .title-effect {
    transform: none;
  }
}

/* sub-effect */
.sub-effect {
  opacity: 1;
  transition: opacity 0.8s;
  transition-delay: 1.2s;
}

/* img */
.page3-imgwrapper {
  opacity: 0;
  transition: 1s;
}

@media (max-width: 1400px) {
  .page3-imgwrapper {
    top: -7%;
    width: initial;
    height: initial;
  }
}

.page3-frameimg {
  opacity: 0.7;
  filter: brightness(1.2);
  object-fit: cover;
}

@media (max-width: 770px) {
  .page3-frameimg {
    height: 70vh;
  }
}

/* img-effect */
.img-effect {
  opacity: 1;
  transition-delay: 0.2s;
  transition-duration: 1.3s;
}
