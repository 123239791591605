.top-button__container {
  display: flex;
  justify-content: flex-end;
  max-width: 1416px;
  margin: -88px auto 0;
  padding: 30px;
  width: 100%;
}

.top-button {
  z-index: 999;
  cursor: pointer;
}

.btn-active {
  display: block;
  opacity: 1;
}

.on {
  position: absolute;
  bottom: 200px;
}

.mobile-on {
  position: absolute;
  bottom: 300px;
}

.top-btn-box {
  display: flex;
  max-width: 1440px;
  justify-content: flex-end;
}