.dot {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: rgba(170, 170, 170, 1);
}

.active-dot {
  height: 27px;
  background-color: rgba(102, 102, 102, 1);
  border-radius: 100px;
}