.roadmap {
  width: 100%;
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  padding: 210px 30px 0;
  opacity: 0;
  transition: 0.5s;
  transform: translateY(30px);
}

.roadmap-table {
  position: relative;
  padding-top: 50px;
  font-size: 5rem;
  color: white;
  z-index: 5;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.02em;
}

.roadmap-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.roadmap-title p:nth-child(1) {
  font-size: 48px;
  letter-spacing: -0.02em;
}

@media (max-width: 1248px) {
  .roadmap-title p:nth-child(1) {
    font-size: 36px;
  }
}

@media (max-width: 770px) {
  .roadmap-title p:nth-child(1) {
    font-size: 40px;
  }
}

.roadmap-title p:nth-child(2) {
  font-size: 15px;
  color: #888888;
  margin-top: 15px;
  letter-spacing: 0px;
}

@media (max-width: 770px) {
  .roadmap-title p:nth-child(2) {
    max-width: 279px;
  }
}

.roadmap-btns {
  display: flex;
  justify-content: center;
  margin: 60px auto 160px;
  gap: 20px;
  z-index: 1;
  position: relative;
}

.roadmap-btns > button {
  background-color: #3e3e3f;
  border-radius: 5px;
  padding: 0.4rem;
  color: #e7e7e7;
  transition: all 0.2s;
  font-weight: 600;
  font-size: 16px;
  width: calc((100% - 20px) / 3);
  max-width: 95px;
}

.roadmap-btns > button:hover,
.roadmap-btns > button.active {
  background-color: #f4f4f4;
  color: black;
  transition: all 0.2s;
  font-weight: 800;
  cursor: pointer;
}

.roadmap-contents {
  color: #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  margin: 0 auto;
}

.roadmap-contents img {
  width: 100%;
  max-width: 1232px;
  height: 100%;
}

.roadmap-contents-small {
  font-size: 14px;
}

.roadmap-contents-4q {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
}

.roadmap-contents-4q::before {
  content: "";
  width: 100%;
  height: 2px;
  background: rgb(85, 85, 85);
  background: linear-gradient(
    90deg,
    rgba(85, 85, 85, 0) 0%,
    rgba(85, 85, 85, 1) 50%,
    rgba(85, 85, 85, 0) 100%
  );
  display: block;
  position: absolute;
  left: 0;
  top: -40px;
}

.roadmap-progress {
  height: 2px;
  background: #bbbbbb;
  display: block;
  position: absolute;
  left: 0;
  top: -40px;
  transition: 1s;
}

.roadmap-progress-circle {
  width: 18px;
  height: 18px;
  background: #ffffff;
  display: block;
  position: absolute;
  right: 0;
  top: -8px;
  border-radius: 100%;
  z-index: 3;
}

.roadmap-progress-circle::after {
  content: "";
  width: 35px;
  height: 35px;
  display: block;
  position: absolute;
  right: -8.5px;
  top: -8.5px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 17.5px;
}

.roadmap-contents-4q ul {
  position: relative;
  width: 300px;
  transition: 0.5s;
}

.roadmap-contents-4q ul::before {
  content: "";
  width: 12px;
  height: 12px;
  background: #555555;
  display: block;
  position: absolute;
  left: 0;
  top: -45px;
  border-radius: 100%;
}

.roadmap-contents-2q {
  display: none;
}

.left-2q,
.right-2q {
  position: relative;
  padding-left: 30px;
}

.left-2q::before {
  content: "";
  width: 2px;
  height: 100%;
  background: rgb(85, 85, 85);
  background: linear-gradient(
    180deg,
    rgba(85, 85, 85, 1) 0%,
    rgba(85, 85, 85, 0) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0;
}

.right-2q::before {
  content: "";
  width: 2px;
  height: 100%;
  background: rgb(85, 85, 85);
  background: linear-gradient(
    180deg,
    rgba(85, 85, 85, 1) 0%,
    rgba(85, 85, 85, 0) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0;
}

.roadmap-contents-2q ul {
  position: relative;
  height: 420px;
}

.roadmap-contents-2q ul::before {
  content: "";
  width: 12px;
  height: 12px;
  background: #555555;
  display: block;
  position: absolute;
  left: -35px;
  top: 0;
  border-radius: 100%;
  z-index: 2;
}

.roadmap-contents ul {
  min-width: 246px;
}

.roadmap-contents ul li {
  margin-bottom: 1.5rem;
  font-size: 18px;
  flex-grow: 0;
}

.roadmap-contents ul li:nth-child(1) {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 28px;
}

.roadmap-contents ul li:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 1248px) {
  .roadmap-btns {
    margin: 40px auto 100px;
  }

  .roadmap-contents {
    padding: 0 30px;
  }
  .roadmap-contents-2q {
    position: relative;
    width: 100%;
    max-width: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  .roadmap-contents-2q.single {
    grid-template-columns: 1fr;
  }

  .left-2q,
  .right-2q {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .roadmap-contents-2q .left-2q,
  .roadmap-contents-2q .right-2q {
    grid-template-rows: 1fr;
  }

  .roadmap-progress {
    height: 0;
    width: 2px;
    background: #bbbbbb;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transition: 1.5s;
  }

  .roadmap-progress-circle {
    width: 18px;
    height: 18px;
    background: #ffffff;
    display: block;
    position: absolute;
    bottom: 0;
    right: -8px;
    top: auto;
    border-radius: 100%;
  }

  .roadmap-progress-circle::after {
    content: "";
    width: 35px;
    height: 35px;
    display: block;
    position: absolute;
    right: -8.5px;
    top: -8.5px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 17.5px;
  }

  .roadmap-contents-4q {
    display: none;
  }
  .roadmap-contents .roadmap-contents-2q.single ul li:nth-child(1) {
    margin-bottom: 0;
  }
}

@media (max-width: 770px) {
  .roadmap-btns {
    margin: 30px auto 70px;
    gap: 10px;
  }

  .roadmap-contents {
    padding: 0;
  }
  .roadmap-contents-2q {
    max-width: 360px;
    grid-template-columns: 1fr;
    gap: 0;
  }

  .roadmap-contents-2q::before {
    content: "";
    width: 2px;
    height: 100%;
    background: rgb(85, 85, 85);
    background: linear-gradient(
      180deg,
      rgba(85, 85, 85, 0) 0%,
      rgba(85, 85, 85, 1) 50%,
      rgba(85, 85, 85, 0) 100%
    );
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  .roadmap-contents-2q article {
    width: 100%;
  }

  .left-2q,
  .right-2q {
    display: block;
  }

  .left-2q ul,
  .right-2q ul {
    margin-bottom: 120px !important;
    height: fit-content;
  }

  .left-2q::before,
  .right-2q::before {
    display: none;
  }

  .roadmap-contents ul li {
    font-size: 16px;
    line-height: 24px;
  }
  .roadmap-contents ul li:nth-child(1) {
    font-size: 22px;
  }
}

.roadmap.translate-up {
  /* padding-top:190px; */
  transform: translateY(0px);
  transition: 0.5s;
  transition-duration: 0.5s;
  opacity: 1;
}

.fade-in {
  animation: fadein 2.5s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fadeout 1.5s;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0%,
  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0%,
  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.table-in {
  animation: table-in 0.25s 0.25s both;
}

@keyframes table-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
