header {
  width: 100%;
  height: 110px;
  position: fixed;
  /* top: 0; */
  z-index: 5;
  padding: 36px 0;
  background-color: transparent;
  transform: initial;
  transition: transform 0.5s;
}

.scrollDown {
  transform: translate(0, -110px);
  transition: transform 0.5s;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  background-color: transparent;
}

.navbar-sitemap {
  font-family: SUIT;
  font-weight: 600;
  display: flex;
  gap: 50px;
  align-items: center;
}
.navbar-sitemap > li {
  position: relative;
  cursor: pointer;
}
.navbar-sitemap > li > span {
  position: absolute;
  top: -2px;
}

@media (max-width: 800px) {
  .navbar-sitemap {
    display: none;
  }
}

@media (max-width: 1440px) {
  header {
    padding: 30px 0;
  }

  .navbar-container {
    margin: 0 30px;
  }
}

@media (max-width: 800px) {
  .navbar-container svg {
    display: block;
    width: 70px;
  }
}

.navbar-mobile-button {
  display: none;
}

.navbar-mobile-button > svg {
  width: unset;
}

@media (max-width: 800px) {
  .navbar-mobile-button {
    display: block;
  }
}