.community-section {
  margin-top: 350px;
  padding-top: 30px;
  opacity: 0;
  transition:0.6s;
  transform: translateY(30px);
}

@media (max-width: 770px) {
  .community-section {
    margin-top: 200px;
  }
}

.community-btns {
  display: flex;
  justify-content: space-between;
  margin: 60px auto 100px;
  position: relative;
  z-index: 5;
  max-width: 500px;
  padding: 0 40px;
}

@media (max-width: 770px) {
  .community-btns {
    margin: 40px auto 50px;
  }
}

.community-btns svg {
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

@media (max-width: 770px) {
  .community-btns svg {
    width: 22px;
    height: 22px;
  }
}

.sns-icon__insta:hover path {
  fill: url(#gradient);
  transition: 0.2s;
}

.sns-icon__discord:hover path {
  fill: #5865f2;
  transition: 0.2s;
}

.sns-icon__twitter:hover path {
  fill: #1da1f2;
  transition: 0.2s;
}

.sns-icon__medium:hover path {
  fill: #ffffff;
  transition: 0.2s;
}

.sns-icon__telegram:hover path {
  fill: #4ca7e9;
  transition: 0.2s;
}

.sns-icon__youtube:hover path {
  fill: #ff0302;
  transition: 0.2s;
}

.sns-icon__gitbook:hover path {
  fill: #4285FD;
  transition: 0.2s;
}

.community-contents {
  display: flex;
  padding: 0 40px;
}

@media (max-width: 770px) {
  .community-contents {
    padding: 0 30px;
  }
}

.community-section.translate-up {
  /* padding-top:0px; */
  transform: translateY(0px);
  transition: 0.5s;
  transition-duration: 0.5s;
  opacity: 1;
}

.community-subtitle br {
  display: none;
}

@media (max-width: 770px) {
  .community-subtitle br {
    display: block;
  }
}