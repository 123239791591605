.background-section {
  background: linear-gradient(180deg, #191919 0%, #202020 100%);
  background-image: url("@/img/common/scroll-background.png");
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 230px;
}

.background-section::before {
  content: "";
  background-image: url("@/img/common/background-section.png");
  mix-blend-mode: difference;
  position: absolute;
  top: 0;
  left: 0;
}