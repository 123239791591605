.artiside-button-wrap {
  margin: 140px 0 80px;
}
.artiside-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 294px;
  height: 54px;
  border-radius: 32px;
  border: 1px solid #9ba1a8;
  color: #333;
  font-family: SUIT;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
