.mobile-nav-menu {
  display: flex;
  flex-direction: column;
  gap: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow: hidden;
  z-index: 5;
}

.mobile-nav-menu__top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  margin: 0 auto;
}

.mobile-nav-menu__top > span > svg {
  width: 100%;
}

.mobile-nav-menu__top > button > svg {
  width: unset;
}

.mobile-nav-menu__center > ul {
  display: flex;
  flex-direction: column; 
}

.mobile-nav-menu__center > ul > li {
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 0;
  border-top: 1px solid rgba(238, 238, 238, 0.93);
  position: relative;
}

.mobile-nav-menu__center > ul > li > span {
  margin-top: -10px;
}

.mobile-nav-menu__center > ul > li > span > svg {
  width: unset;
}

.mobile-nav-menu__center > ul > li:last-child {
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}

.mobile-nav-menu__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}