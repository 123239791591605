.error-page {
  max-width: 1920px;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.error-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.error-bg-image img {
  width: 100%;
  height: 100%;
}

.error-info {
  max-width: 500px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

.error-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.error-frame-svg {
  position: absolute;
}

.error-icon {
  bottom: 45%;
}

.error-text {
  bottom: 32%;
}

@media (max-width: 500px) {
  .error-icon {
    width: 58px;
  }

  .error-text {
    width: 120px;
  }
}

.error-msg {
  margin-top: -30px;
}

.error-info img {
  width: 100%;
}

@media (max-width: 500px) {
  .error-frame {
    width: 278px;
  }
  
  .error-msg svg {
    width: 280px;
  }

  .error-btn svg {
    width: 180px;
  }
}
