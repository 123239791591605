html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: black;
  -webkit-font-smoothing: antialiased;
}

* {
  font-family: "SUIT", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  list-style: none;
  text-decoration: none;
}

a {
  color: white;
}

.App {
  position: relative;
}

.white {
  color: white;
}

button {
  cursor: pointer;
}