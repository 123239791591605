.scroll-icon {
  padding-top: 40px;
}

.frame-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  width: fit-content;
  height: fit-content;
}

.main-img {
  background-size: cover;
  width: 100%;
  max-width: 1920px;
  min-width: 1920px;
  margin: 0 auto;
  height: 1350px;
  position: relative;
  display: flex;
  justify-content: center;
  /* transform: scaleX(-1); */
}

.content-video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-img::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 20%;
  z-index: 1;
  background-image: linear-gradient(
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0)
  );
}

.main-img::after {
  content: "";
  background-image: url("@/img/1.main/line.svg");
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 145px;
  z-index: 1;
}

.main-sub-text {
  padding: 1rem 0;
  z-index: 2;
  color: white;
  font-size: 18px;
  line-height: 36px;
  text-align: start;
}

@media (min-width: 1401px) {
  .main-sub-text {
    width: 250px;
  }
}

.main-sub-text p::first-letter {
  font-size: 24px;
  font-weight: 600;
}

.main-header-text {
  color: white;
  z-index: 2;
  text-align: center;
  position: absolute;
  top: 11%;
}

.main-header-text > p {
  margin: 0;
  font-size: 66px;
  margin-bottom: 1.5rem;
}

.main-header-text > p:nth-child(2) {
  font-size: 40px;
  line-height: 55.2px;
}

.texture {
  width: 100%;
  max-width: 1920px;
  height: 462px;
  margin: 0 auto;
}

.frame-bg {
  max-width: 1920px;
  max-height: 1660px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 1660px;
  background-position: center -40px;
  position: relative;
  overflow-x: hidden;
}

.pillar {
  margin-top: -400px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pillar img {
  width: 508px;
  z-index: 1;
}

.pillar video {
  position: absolute;
  width: 368px;
  margin-top: -20px;
}

.text-container {
  max-width: 1120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.top-text {
  font-size: 40px;
  position: absolute;
  top: 327px;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 8px;
}

.top-text p:nth-child(1) {
  font-size: 66px;
  margin-bottom: 25px;
}

@media (max-width: 1280px) {
  .top-text p:nth-child(1) {
    font-size: 64px;
  }

  .top-text p:nth-child(2) {
    font-size: 36px;
  }
}

.text-container-mob {
  margin-top: 16px;
  display: none;
  width: 570px;
  overflow-x: scroll;
  text-align: center;
  opacity: 0;
}

.text-container-mob::-webkit-scrollbar {
  display: none;
}

.text-container-web {
  display: flex;
}

@media (max-width: 1280px) {
  .text-container-web {
    display: none;
  }

  .text-container-mob {
    display: flex;
    justify-content: normal;
    margin-top: 938px;
    min-width: 570px;
  }

  .main-sub-text {
    width: 100%;
  }

  .sub-effect {
    opacity: 1;
    transition: 0.5s;
    transition-delay: 1s;
  }

  .scotch-font {
    font-size: 40px;
  }

  .mobile-text {
    font-size: 1rem;
    line-height: 30px;
    font-weight: 400;
    margin-top: 70px;
    max-width: 480px;
  }

  .accent {
    font-size: 88px;
  }

  .accent-10 {
    font-size: 76px;
  }

  .contents-title p:nth-child(1) {
    font-size: 18px;
    color: #bbbbbb;
    margin-bottom: 40px;
  }

  .contents-title p:nth-child(2) {
    margin-left: 0;
  }

  .contents-title p:nth-child(3) {
    margin-left: 0;
  }

  .contents-title-start {
    margin-left: -70px;
  }

  .contents-title-end {
    margin-left: 70px;
  }

  .contents-title-end p:nth-child(1) {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .scotch-font {
    font-size: 24px;
  }
}

.test {
  justify-content: end;
}

@media (max-width: 800px) {


  .top-text {
    padding: 0 30px;
  }

  .top-text p:nth-child(1) {
    font-size: 40px;
  }

  .top-text p:nth-child(2) {
    font-size: 20px;
  }

  .frame-bg {
    align-items: flex-start;
    background-size: 1040px;
    background-position-y: -40px;
    background-position-x: center;
  }

  .pillar {
    height: 740px;
    margin-top: 0px;
  }

  .pillar img {
    width: 320px;
    height: auto;
  }

  .pillar video {
    width: 220px;
    margin-top: -10px;
  }

  .top-text {
    top: 110px;
  }

  .top-text p:nth-child(1) {
    font-size: 36px;
    margin-bottom: 5px;
  }

  .top-text p:nth-child(2) {
    width: 280px;
    font-size: 18px;
    margin: 0 auto;
  }

  .text-container-mob {
    display: flex;
    justify-content: normal;
    margin-top: 586px;
    min-width: auto;
  }

  .main-sub-text {
    width: 100%;
    line-height: 30px;
    text-align: center;
    padding: 0 30px;
    font-size: 16px;
  }
}

.text-line {
  display: flex;
  gap: 10px;
  margin-bottom: 21px;
}

.text-line-left {
  margin-left: 10px;
}

@media (min-width: 1280px) and (max-height: 1080px) {
  .frame-bg {
    background-position: center -100px;
  }
  .pillar {
    margin-top: -520px;
  }
  .top-text {
    top: 380px;
  }
}

/* swiper custom */
.swiper {
  padding-top: 10px !important;
}
.swiper-pagination {
  top: -10px !important;
  z-index: 0 !important;
  height: fit-content;
}

.main-swiper .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border-radius: 0px !important;
  width: 13px !important;
  height: 2px !important;
}

.main-swiper .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
  border-radius: 0px !important;
  width: 30px !important;
  height: 2px !important;
}

.section-wrapper {
  height: 200vh;
  width: 100vw;
}

.page3-section::-webkit-scrollbar {
  display: none;
}

/* artiside */
/* muted button */
.muted-btn {
  z-index: 10;
  cursor: pointer;
  display: flex;
  gap: 8px;
  margin-right: 18px;
  min-width: 102px;
  position: absolute;
  top: 85%;
}

.muted-btn__artiside {
  max-width: 446px;
  width: 100%;
  justify-content: flex-end;
  margin: 0 auto;
  top: 103%;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .muted-btn__artiside {
    max-width: 380px;
    height: fit-content;
    bottom: 0px;
  }
}

@media (max-width: 768px) {
  .muted-btn {
    min-width: 83px;
    top: 60px;
    right: -150px;
  }

  .muted-btn__artiside {
    top: 348px;
    max-width: 85px;
    justify-content: flex-end;
    right: 0%;
  }

  .muted-btn > svg {
    width: 19px;
    height: 17px;
  }
}

.muted-info {
  color: #9cbccd;
  font-size: 14px;
  font-weight: 600;
}

.muted-info__artiside {
  color: #888888;
}

@media (max-width: 768px) {
  .muted-info {
    font-size: 12px;
  }
}