.page3-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  z-index: -1;
}

.page3-wrapper__contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100vw;
  max-width: 1920px;
}

@media (max-width: 1400px) {
  .page3-section__contents {
    display: grid;
    grid-template-columns: 1fr;
  }

  .page3-wrapper__contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 96vh;
  }
}

.page3-imgwrapper__contents {
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  z-index: -1;
  margin-top: 6%;
  opacity: 0.7;
  object-fit: cover;
}

@media (max-width: 1400px) {
  .page3-imgwrapper__contents::after {
    content: "";
    position:absolute;
    top: 27%;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9346332282913166) 30%, rgba(0,0,0,0) 60%);

  }
}

@media (max-width: 770px) {
  .page3-imgwrapper__contents {
    position: absolute;
  }

  .mobile-text__contents br {
    display: none;
  }
}

@media (max-width: 1400px) {
  .mobile-text___contents br {
    display: none;
  }
}

.page3-imgwrapper__contents img {
  position: absolute;
  bottom: 130px;
}

.content-text {
  position: absolute;
  color: white;
  font-size: 38px;
}

@media (min-width: 1401px) {
  .content-text {
    min-width: 650px;
  }
}

@media (max-width: 1400px) {
  .content-text {
    font-size: 40px;
    margin-left: 0;
    top: 73%;
    left: initial;
    position: initial;
    /* margin-top: 254px; */
    margin-top: 60%;
    z-index: 1;
  }
}

.text-top__contents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 0%;
}

@media(min-width: 1401px) {
  .text-top__contents p:nth-child(2) {
    margin-left: -360px;
  }
}


@media (max-width: 1400px) {
  .text-top__contents {
    align-items: flex-start;
  }
}

.text-bottom__contents {
  justify-content: flex-end;
  margin-left: 401px;
  font-size: 38px;
  margin-top: -15px;
}

@media (max-width: 1400px) {
  .text-bottom__contents {
    margin-left: 0px;
    margin-top: -5px;
  }
}

.before-line {
  display: none;
  margin-right: 40px;
}

.before-line2 {
  display: none;
  margin-right: 40px;
}

@media (min-width: 1401px) {
  .before-line {
    display: block;
  }
}

@media (max-width: 1400px) {
  .before-line2 {
    display: block;
  }
}

.mobile-text__contents {
  margin-top: 30px;
}

.sub-text__contents {
  margin-top: 190px;
  display: none;
}

@media (min-width: 1399px) {
  .sub-text__contents {
    display: block;
    margin-top: 400px;
  }
}

@media (max-width: 1400px) {
  .sub-text__contents {
    margin-top: 70px;
  }
}

@media (min-width: 2180px) {
  .sub-text__contents {
    margin-top: 530px;
  }
}

.sub-text__contents p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 1px;
  color: #aaaaaa;
  min-width: 350px;
  text-align: center;
  /* margin-left: 125px; */
}

.sub-text__contents p::first-letter {
  font-size: 24px;
  font-weight: 600;
}

.page3-imgwrapper__contents img:nth-child(1) {
  height: 50%;
  bottom: 300px;
}

.page3-imgwrapper__contents img:nth-child(2) {
  height: 82%;
}

@media (max-width: 770px) {
  .page3-imgwrapper__contents img:nth-child(2) {
    width: 80%;
    height: initial;
    bottom: 185px;
  }
}

@media (max-width: 1400px) {
  .page3-imgwrapper__contents {
    top: -25%;
    width: 100%;
    height: 100%;
  }

  .page3-imgwrapper__contents img {
    height: 90vh;
    /* width: 100%; */
    object-fit: cover;
  }
}

@media (max-width: 770px) {
  .page3-imgwrapper__contents img {
    height: 70vh;
  }
}

.text-accent__contents {
  font-size: 76px;
}

@media (max-width: 1400px) {
  .text-accent__contents {
    font-size: 88px;
  }
}

@media (max-width: 770px) {
  .content-text {
    font-size: 24px;
    min-width: none;
    padding: 0 30px;
  }

  .text-accent__contents {
    font-size: 40px;
  }
}

.content-effect {
  transition: transform 1s;
}

.web-page3-text {
  display: none;
  margin-top: 5%;
}

@media (min-width: 1401px) {
  .web-page3-text {
    display: block;
    opacity: 0;
    margin-top: 7%;
  }
}

.mobile-text-start {
  display: flex;
  justify-content: flex-start;
}

.mobile-text-end .text-bottom__contents p {
  text-align: end;
}

/* Animations */
.opacity-effect {
  transition-delay: 3s;
  transition: 0.8s;
  opacity: 1;
}

.page3-imgwrapper__contents {
  opacity: 0;
  transition-delay: 0.2s;
}

.img-effect__contents {
  opacity: 1;
  transition-delay: 0.2s;
}

@media (min-width: 1401px) {
  .title-effect__contents {
    opacity: 1;
    transition: 0.5s;
    transition-delay: 0.2s;
    transform: translateY(15%);
    transition-duration: 1.2s;
  }
}

.sub-text__contents {
  opacity: 0;
  transition: 0.5s;
}

@media (min-width: 1401px) {
  .sub-effect__contents {
      opacity: 1;
      transition: 0.5s;
      transition-delay: 0.3s;
      transform: translateY(-20%);
      transition-duration: 1s;
    }
}

.page3-sns-icons {
  z-index: 5;
  position: absolute;
  max-width: 1416px;
  width: 100%;
  max-height: 524px;
  height: 100%;
}

.sns-icon {
  position: absolute;
  opacity: 0;
}

.page3-sns-icons svg:nth-child(1) {
  top: 18%;
  width: 60px;
  transition-delay: 1.7s;
}

@media (max-width: 770px) {
  .page3-sns-icons svg:nth-child(1) {
    left: 20%;
    top: 25%;
    width: 32px;
  }
}

.page3-sns-icons svg:nth-child(2) {
  right: 0;
  top: 40%;
  width: 60px;
  transition-delay: 2s;
}

@media (max-width: 770px) {
  .page3-sns-icons svg:nth-child(2) {
    top: 45%;
    width: 36px;
    right: 5%;
  }
}

.page3-sns-icons svg:nth-child(3) {
  bottom: 210px;
  left: 100px;
  transition-delay: 2.5s;
  width: 40px;
}

@media (max-width: 770px) {
  .page3-sns-icons svg:nth-child(3) {
    top: 48%;
    left: 2%;
    width: 26px;
  }
}

.page3-sns-icons svg:nth-child(4) {
  top: 25%;
  right: 12%;
  transition-delay: 2.5s;
  width: 40px;
}

@media (max-width: 770px) {
  .page3-sns-icons svg:nth-child(4) {
    top: 25%;
    right: 20%;
    transition-delay: 3s;
    width: 26px;
  }
}

.page3-sns-icons svg:nth-child(5) {
  bottom: 0;
  top: 75%;
  right: 10%;
  transition-delay: 3s;
  width: 50px;
}

@media (max-width: 770px) {
  .page3-sns-icons svg:nth-child(5) {
  bottom: 0;
  top: 63%;
  right: 15%;
  transition-delay: 3s;
  width: 26px;
  }
}

.btn-effect {
  opacity: 1;
  transition: 1s;
}

.contents-articles {
  bottom: 70px;
  height: 80%;
}

@media (min-width: 768px) and (max-width: 1400px) {
  .content-text {
    margin-top: 33%;
  }

  .title-section__contents {
    align-items: center;
  }
}